import { captureException } from '@sentry/browser'
import { DocumentNode } from 'graphql'

import { apolloCache } from 'web/src/apolloCache/apolloCache'

type anyRecord = Record<string, any>

interface UpdateCacheProps {
  query: DocumentNode
  mutationResult: anyRecord
  updatedField: string
  queryVariables?: anyRecord
}

export const updateCache = ({
  query,
  mutationResult,
  updatedField,
  queryVariables,
}: UpdateCacheProps) => {
  // DON'T USE THIS FUNCTION FOR AN UPDATE MUTATION
  // These should be handled automatically by Apollo
  const ENVIRONMENT = process.env.ENVIRONMENT

  const existingData = apolloCache.readQuery<anyRecord>({
    query,
    variables: queryVariables,
  })

  if (!existingData && ENVIRONMENT !== 'local') {
    captureException(
      new Error(`unable to update cache for field ${updatedField}`),
      {
        extra: {
          query,
          mutationResult,
          updatedField,
          queryVariables,
        },
      },
    )
    // This should never happen but it's a better result then nothing happening at all.
    window.location.reload()
  }

  const recordInCache = existingData[updatedField].some(
    (item: any) => item.id === mutationResult.id,
  )

  let updatedData: anyRecord

  if (recordInCache) {
    // record already exists in cache, assume we are deleting it
    updatedData = {
      ...existingData,
      [updatedField]: existingData[updatedField].filter(
        (item: any) => item.id !== mutationResult.id,
      ),
    }
  } else {
    // new record, add it to cache
    updatedData = {
      ...existingData,
      [updatedField]: [...existingData[updatedField], mutationResult],
    }
  }

  apolloCache.writeQuery<anyRecord>({
    query,
    data: updatedData,
    variables: queryVariables,
  })
}
